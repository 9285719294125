






























import MNotificationVue from "@/mixins/MNotification.vue";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { batchService } from "@service/batch.service";
import { IOption } from "@interface/common.interface";
import Vue from "vue";
import { debounceProcess } from "@/helpers/debounce";
import { ResponseListBatch } from "@interface/batch.interface";

export default Vue.extend({
  name: "CSelectBatchNumber",
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    byProduct: {
      type: String,
      default: undefined,
    }
  },
  data() {
    this.searchBatchNumber = debounceProcess(this.searchBatchNumber, 300);
    return {
      dtOpt: [] as IOption[],
      loading: false,
      queryParams: {
        page: 0,
        limit: 10,
        search: ""
      },
      dtListBatchNumber: {} as ResponseListBatch
    };
  },
  watch: {
    byProduct: {
      immediate: true,
      handler: "onChangeProduct"
    }
  },
  created() {
    if (this.byProduct) {
      this.queryParams.search = `product.secureId~${this.byProduct}`;
    }
    this.getList(this.queryParams);
  },
  methods: {
    onChangeProduct(newVal: string): void {
      if (!newVal) return;
      this.queryParams.search = `product.secureId~${this.byProduct}`;
      this.dtOpt = [];
      this.getList(this.queryParams);
    },
    getListBatch(params: RequestQueryParamsModel): Promise<ResponseListBatch> {
      return batchService.listBatch(params);
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListBatch(params);
        const { dtOpt } = this;
        const opts = res.data.map(x => (
          {
            key: x.batchNumber,
            value: x.id,
            meta: x
          }
        ));
        this.dtOpt = [...dtOpt, ...opts];
        this.dtListBatchNumber = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    searchBatchNumber(search = ""): void {
      if (search) {
        this.queryParams.search = `batchNumber~*${search}*`;
      }
      if (this.byProduct) {
        this.queryParams.search = `batchNumber~*${search}*_AND_product.secureId~${this.byProduct}`;
      }
      this.queryParams.page = 0;
      this.dtOpt = [];
      this.getList(this.queryParams);
    },
    popupScroll(e): void {
      if ((this.dtListBatchNumber.totalPages - 1) === this.dtListBatchNumber.currentPage) return;
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.queryParams.page += 1;
        this.getList(this.queryParams);
      }
    },
    onSelect(val: string, meta: IOption): void {
      this.$emit("input", val);
      this.$emit("on-select", { value: val, meta });
    },
  },
});

